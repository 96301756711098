export const BMS_TABLES = {
	insane: "★",
	overjoy: "★★",
	normal: "☆",
	normal2: "▽",
	insane2: "▼",
	stella: "st",
	satellite: "sl",
	dpNormal: "δ",
	dpInsane: "★",
};
