import { ChangeOpacity } from "util/color-opacity";
import { FormatTables } from "util/misc";
import QuickTooltip from "components/layout/misc/QuickTooltip";
import Icon from "components/util/Icon";
import React from "react";
import { ChartDocument, COLOUR_SET } from "tachi-common";
import Muted from "components/util/Muted";
import TierlistInfoPart from "./TierlistInfoPart";

export default function BMSPMSDifficultyCell({
	chart,
	game,
}: {
	chart: ChartDocument<"bms:7K" | "bms:14K" | "pms:Controller" | "pms:Keyboard">;
	game: "bms" | "pms";
}) {
	const hasLevel = chart.data.tableFolders.length > 0;

	const aiLevel = game === "bms" && (chart as ChartDocument<"bms:7K" | "bms:14K">).data.aiLevel;

	let levelText = "No Rating";
	if (hasLevel) {
		levelText = FormatTables(chart.data.tableFolders);
	}

	return (
		<td
			style={{
				backgroundColor: ChangeOpacity(hasLevel ? COLOUR_SET.red : COLOUR_SET.gray, 0.2),
			}}
		>
			{!hasLevel && aiLevel ? (
				<>
					<span>No Rating</span>
					<QuickTooltip tooltipContent="This rating has been generated by AI. Don't put too much trust into it.">
						<div>
							<Muted>AI{aiLevel}</Muted>
						</div>
					</QuickTooltip>
				</>
			) : (
				<span>{levelText}</span>
			)}

			<TierlistInfoPart chart={chart} game={game} />
			{!chart.isPrimary && (
				<QuickTooltip tooltipContent="This chart is an alternate, old chart.">
					<div>
						<Icon type="exclamation-triangle" />
					</div>
				</QuickTooltip>
			)}
		</td>
	);
}
